import styled from "styled-components";
import { useContext } from "react";
import background from "../../images/bg_dark.mp4";
import logo from "../../images/logo.png";
import { colors, EthereumContext, useTitle, Button, constants } from "../index";
import { Loading, Status } from "../components";
import { TopAlert } from "../components";
import { Background } from "../index";

const ConnectPageSection = styled.div`
  position: relative;
  background-color: white;
  flex-direction: column;
  flex: 1;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  display: flex;

  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const ConnectFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 2;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  z-index: 2;
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

const ConnectButtonFrame = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
  max-width: 500px;
  @media screen and (max-width: 1050px) {
    // margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    // border: 0px;
    width: 80%;
    justify-content: center;
  }
`;

const LogoFrame = styled.div`
  background-color: black;
  border-radius: 50%;
  width: fit-content;
  margin-top: 50px;
  margin-bottom: 100px;
  @media screen and (max-width: 1050px) {
    margin-top: 50px;
    margin-bottom: 35px;
  }
`;

const LogoImg = styled.img`
  width: 250px;
  @media screen and (max-width: 1050px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Join = styled.p`
  font-family: "Jockey One";
  font-style: normal;
  // font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  background: white;
  color: black;
  padding-top: 0px;
  // text-shadow: #ffffff 3px 3px 0px, rgb(210 210 210 / 20%) 6px 6px 0px;
  text-align: center;
  @media screen and (max-width: 1050px) {
    font-size: 25px;
  }
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

const VideoPlayer = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  // z-index: -100;
`;

const Video = () => {
  return (
    <VideoPlayer autoPlay loop muted>
      <source src={background} />
    </VideoPlayer>
  );
};

export const Connect: React.FunctionComponent = () => {
  useTitle({ page: `Mint ${constants.token.symbol}` });
  const { contractState, connect } = useContext(EthereumContext);

  return (
    <ConnectPageSection className="noselect">
      {/* <Background background={background}></Background> */}
      <Video />
      <ConnectFrame>
        <LogoFrame>
          <LogoImg src={logo} alt={`${constants.projectName} Logo`} />
        </LogoFrame>

        <ConnectButtonFrame>
          <Button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            onClick={connect}
          >
            Connect Wallet
          </Button>
        </ConnectButtonFrame>
        {/* Display remaining supply  */}
        {/* <Status>
          {contractState &&
            contractState.MAX_SUPPLY &&
            contractState.supplyRemaining ? (
              `${
                contractState.supplyRemaining
              }/${contractState.MAX_SUPPLY.toNumber()} Remaining`
              ) : (
                <Loading text="supply" />
                )}
              </Status> */}
      </ConnectFrame>
    </ConnectPageSection>
  );
};

import { useContext } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  EthereumContext,
  Balance,
  Blockie,
  FlexRow,
  FlexColumn,
} from "../index";
import { useTheme } from "../contexts";

const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  color: white;
  transition: 2s ease-in-out all;
  background-color: black;
  font-family: yeet;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  @media screen and (max-width: 1000px) {
    width: 100%;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const AddressElem = styled.p`
  font-family: "Courier New", Courier, monospace;
  margin: 0;
  font-weight: 600;
  font-size: 0.8rem;
  @media screen and (max-width: 400px) {
    width: 100%;
    font-size: 0.75rem;
  }
`;

const DisconnectBtn = styled.button`
  margin: 0;
  padding: 0;
  background-color: #9de1eb;
  color: black;
  border: 0;
  border-radius: 6px;
  padding: 2px 6px;
  transition: 0.3s ease-in-out all;
  box-sizing: border-box;
  box-shadow: black 2px 2px 0px, black 2px 2px 0px, 2px 1px 3px rgba(0, 0, 0, 0);
  font-family: Jockey One;
  font-size: 25px;
  @media screen and (max-width: 430px) {
    font-size: 18px;
  }
  &:hover {
    background-color: #73d4e2;
    font-weight: bold;
  }
`;

function addressConcat(addr: string): string {
  const l = addr.length,
    n = 12;
  return addr.substring(0, n) + "..." + addr.substring(l - n, l);
}

const Address = ({ address }: { address: string | null | undefined }) => {
  const { logout } = useContext(EthereumContext);
  const { bgColor } = useTheme()!;
  return address ? (
    <FlexRow
      style={{
        alignItems: "center",
      }}
    >
      <div
        style={{
          paddingRight: 10,
        }}
      >
        <Blockie address={address} />
      </div>
      <FlexColumn style={{ marginRight: 10 }}>
        <AddressElem>{addressConcat(address)}</AddressElem>
        <div>
          <Balance />
        </div>
      </FlexColumn>
      <DisconnectBtn
        onClick={() => {
          toast.dark("Disconnected from MetaMask", { autoClose: 1500 });
          if (logout) {
            logout();
          }
        }}
      >
        Disconnect
      </DisconnectBtn>
    </FlexRow>
  ) : (
    <></>
  );
};

export const TopAlert = (): JSX.Element => {
  const { errorMsg, account } = useContext(EthereumContext);
  return account ? (
    <Alert className="noselect">
      <Address address={account} />
    </Alert>
  ) : errorMsg ? (
    <Alert>{errorMsg}</Alert>
  ) : (
    <></>
  );
};
